import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import '@/custom/components/molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '@/product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BuyerContext from '@/product/context/buyer/buyerContext'
import AuthContext from '@/product/context/auth/authContext'
import CustomDialog from '@/custom/components/organisms/Dialog'
import { handleRedirectInternal } from '@/custom/common/components'
import { currencyFormat } from '@/custom/common/components'
import { mapData } from '@/product/common/components'
import { socket } from '@/product/common/socket'
import moment from 'moment'

let serverTime = new Date()

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)

    let { bidConfirm } = buyerContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = useState()

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('id', product.id)
        }
    }, [product])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(product && product.sprice ? product.sprice : 1, `${`Enter Valid Price`} `)
            .required('Enter Offer Price / Ct'),
    })

    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: 0,
            bidding_type: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                bidConfirm(values)
                formik.setTouched({})
                formik.values.wsprice = ''
                setToggleDialog(false)
                if (props.handleNext) {
                    props.handleNext()
                }
            } else {
                formik.setSubmitting(false)
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (socket) {
            socket.on('servertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    serverTime = new Date(data.dTime)
                }
            })
        }
    }, [socket])

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const bidAmount = [
        {
            label: 'Enter Price / Ct',
            name: 'wsprice',
            type: 'number',
            placeholder: `${
                product && product.current_bid
                    ? `Current Offer ${
                          product ? currencyFormat(product.current_bid) : currencyFormat(0)
                      }`
                    : `Enter Offer Price / Ct`
            }`,
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' && product.past_inactive ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="sealedBiddingCnt">
                                {mapData(bidAmount)}
                                {isAuthenticated ? (
                                    <PrimaryButton
                                        label={
                                            product.bid_or_not ? 'Edit my offer' : 'Place an offer'
                                        }
                                        type="submit"
                                        btnSize="small"
                                        id="placebid_button"
                                    />
                                ) : null}
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title="Are you sure you would like to place an offer?"
                        open={toggleDialog}
                        className="bidCnfDialog"
                        function={changeDialogStatus}
                    >
                        <h5>Please verify your offer to confirm!</h5>
                        <h6>
                            Offer Price:{' '}
                            <span>{currencyFormat(formik?.values?.wsprice)} per carat</span>
                        </h6>
                        <h6>
                            Total Value:{' '}
                            <span>
                                {currencyFormat(
                                    parseFloat(formik?.values?.wsprice) *
                                        parseFloat(product?.weight || 1),
                                )}
                            </span>
                        </h6>
                        <div className="actionWrapper">
                            <Button
                                variant="outlined"
                                id="bidcancel_button"
                                onClick={() => setToggleDialog(false)}
                            >
                                No, Cancel
                            </Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                id="bidconfirm_button"
                                type="button"
                                disabled={formik.isSubmitting}
                                label="Yes, Confirm"
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
