import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import '@/custom/components/molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '@/product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BuyerContext from '@/product/context/buyer/buyerContext'
import AuthContext from '@/product/context/auth/authContext'
import PayContext from '@/product/context/payment/payContext'
import CreditContext from '@/product/context/credit/creditContext'
import { useTranslation } from 'react-i18next'
import ProductContext from '@/product/context/product/productContext'
import CommonContext from '@/product/context/common/commonContext'
import CustomDialog from '@/custom/components/organisms/Dialog'
import { handleRedirectInternal } from '@/custom/common/components'
import { currencyFormat } from '@/custom/common/components'
import { mapData } from '@/product/common/components'

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)
    const creditContext = useContext(CreditContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { t } = useTranslation()

    const { searchValue } = commonContext
    const { getAllSearchProducts } = productContext

    const { payCartRosoom } = payContext
    const { addBidDeposit, responseStatus: responseStatusCredit } = creditContext

    let { bidConfirm } = buyerContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = useState()

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    useEffect(() => {
        if (responseStatusCredit) {
            // console.log('responseStatusCredit', responseStatusCredit)
            if (responseStatusCredit.from === 'addBidDeposit') {
                if (responseStatusCredit.status === 'success') {
                    setToggleDialogDeposit(false)
                    getAllSearchProducts(searchValue)
                }
            }
        }
    }, [responseStatusCredit])

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('id', product.id)
            formikPayment.setFieldValue('cart_id', product.id)
        }
    }, [product])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(
                product ? product.next_bid : 0,
                `${'Min bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .required('Enter Bid Amount'),
    })
    const validationPayment = Yup.object({
        payment_method: Yup.string().required('Required'),
    })
    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: 0,
            bidding_type: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (product.deposit && !product.bid_deposit_id) {
                formik.setSubmitting(false)
                setToggleDialogDeposit(true)
            } else if (toggleDialog) {
                bidConfirm(values)
                formik.setTouched({})
                formik.setFieldValue('wsprice', '')
                setToggleDialog(false)
                if (props.handleNext) {
                    props.handleNext()
                }
            } else {
                formik.setSubmitting(false)
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        formik.setFieldValue('bidding_type', props.type)
    }, [props.type])

    useEffect(() => {
        formik.setFieldValue('wsprice', product?.next_bid)
    }, [product?.next_bid])

    const formikPayment = useFormik({
        initialValues: {
            cart_id: 0,
            pay_type: 'bid_deposit',
            payment_method: '',
        },
        validationSchema: validationPayment,
        onSubmit: (values) => {
            formikPayment.setSubmitting(true)
            if (values.payment_method === 'wallet') {
                let formData = {
                    amount: product.deposit_amount,
                    product_id: values.cart_id,
                    credit_notes: `Bid Deposit - ${product.title}`,
                    credit_type: 'user',
                    type: 'projects',
                    creditor_id: user.id,
                }
                addBidDeposit(formData)
            } else if (values.payment_method === 'online') {
                let formData = {
                    cart_id: values.cart_id,
                    payment_object: {
                        type: 'projects',
                        amount: 0,
                    },
                    pay_type: values.pay_type,
                }
                payCartRosoom(formData)
            }
        },
    })

    const bidAmount = [
        {
            label: t('Enter Bid Amount'),
            name: 'wsprice',
            type: 'number',
            placeholder: t(
                `${'Min bid'} ${
                    product && product.next_bid
                        ? currencyFormat(product.next_bid)
                        : currencyFormat(0)
                }`,
            ),
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
        },

        // {
        //     helperText:
        //         props.helpText &&
        //         (product && product.bidhistory.length > 0
        //             ? 'Enter ' + currencyFormat(product.next_bid) + ' or more'
        //             : product && 'Minimum_Bid' + ' ' + currencyFormat(product.next_bid)),
        //     label: 'Enter Bid Amt',
        //     name: 'wsprice',
        //     type: 'number',
        //     placeholder: `${'Min Bid'} ${
        //         product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
        //     }`,
        //     class: '',
        //     size: 'small',
        //     disabled: canBid ? false : true,
        //     autoFocus: false,
        //     formik: formik,
        //     onChange: removeAlpha,
        // },
    ]
    const modeOfPayment = [
        {
            label: t('Select mode of payment'),
            placeholder: t('Select mode of payment'),
            type: 'select',
            class: 'col-12',
            options: [
                { value: 'online', show: t('online') },
                { value: 'wallet', show: t('wallet') },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start">
                                {/* {Object.values(mapData(bidAmount))} */}
                                {isAuthenticated ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? `Increase bid at ${currencyFormat(
                                                          product.next_bid,
                                                      )}`
                                                    : 'Increase proxy bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                            id="increasebid_button"
                                        />
                                    ) : (
                                        <PrimaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? `Place bid at ${currencyFormat(
                                                          product.next_bid,
                                                      )}`
                                                    : 'Place proxy bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                            id="placebid_button"
                                        />
                                    )
                                ) : null}
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title="Are you sure you want to place your bid?"
                        open={toggleDialog}
                        className="bidCnfDialog"
                        function={changeDialogStatus}
                    >
                        <h5>Please verify your bid details to confirm your bid!</h5>
                        <h6>
                            Bid Price:{' '}
                            <span>{currencyFormat(formik?.values?.wsprice)} per carat</span>
                        </h6>
                        <h6>
                            Total Value:{' '}
                            <span>
                                {currencyFormat(
                                    parseFloat(formik?.values?.wsprice) *
                                        parseFloat(product?.weight || 1),
                                )}
                            </span>
                        </h6>
                        <div className="actionWrapper">
                            <Button
                                variant="outlined"
                                id="bidcancel_button"
                                onClick={() => setToggleDialog(false)}
                            >
                                No, Cancel
                            </Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                id="bidconfirm_button"
                                type="button"
                                disabled={formik.isSubmitting}
                                label="Yes, Confirm"
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={t('Submit a deposit')}
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                    >
                        <h4>{t('Deposit has to be placed before placing bid on the product')}</h4>
                        <h5>
                            {t('Deposit amount')}: {currencyFormat(product.deposit_amount)}
                        </h5>

                        <div className="row">{Object.values(mapData(modeOfPayment))}</div>

                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialogDeposit(false)}>
                                {t('Cancel')}
                            </Button>
                            <PrimaryButton
                                onClick={formikPayment.handleSubmit}
                                type="button"
                                label={t('Submit')}
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
